import { orderBy } from "lodash";
// types
import { IElementShort } from "src/@types/element";
import { IElementFilters, INarrativeFilters, IPeriod } from "src/@types/filter-search";
import { INarrativeShort } from "src/@types/narrative";

// ----------- DEFAULT ELEMENT VALUES -----------------

export const defaultElementFilters: IElementFilters = {
	name: "",
	tags: [],
	period: { min: 0, max: 0 }, // not used, instead we use the periodLimits.min & periodLimits.min
	year: 0,
};

export const defaultElementSort = "dateAsc";

// ----------- DEFAULT NARRATIVE VALUES -----------------

export const defaultNarrativeFilters: INarrativeFilters = {
	name: "",
};

export const defaultNarrativeSort = "nameAsc";

// ----------- ELEMENT MANAGEMENT -----------------

export function equalDefaultElementFilters(
	filters: IElementFilters,
	periodLimits: IPeriod
): boolean {
	// name
	if (filters.name !== defaultElementFilters.name) return false;
	// tags
	if (!(filters.tags.length === 0 && defaultElementFilters.tags.length === 0)) {
		if (filters.tags.length === 0 && defaultElementFilters.tags.length !== 0)
			return false; // common case
		else {
			// full check
			if (new Set(filters.tags).size !== new Set(defaultElementFilters.tags).size) return false;
			else
				for (const tag of filters.tags) if (!defaultElementFilters.tags.includes(tag)) return false;
		}
	}
	// period
	if (filters.period.min !== periodLimits.min || filters.period.max !== periodLimits.max)
		return false;
	// year
	if (filters.year !== defaultElementFilters.year) return false;
	// otherwise true
	return true;
}

export function applyElementFilter(
	elements: IElementShort[],
	filters: IElementFilters,
	sortBy: string,
	periodLimits: IPeriod
): IElementShort[] {
	const { name, tags, period, year } = filters;

	// SORT BY
	if (sortBy === "lastUpdated") elements = orderBy(elements, ["updatedAt"], ["desc"]);
	if (sortBy === "nameAsc") elements = orderBy(elements, ["name"], ["asc"]);
	if (sortBy === "nameDesc") elements = orderBy(elements, ["name"], ["desc"]);
	if (sortBy === "dateAsc")
		elements = orderBy(
			elements,
			[
				function (element) {
					return element.dates.startDate?.date;
				},
			],
			["asc"]
		);
	if (sortBy === "dateDesc")
		elements = orderBy(
			elements,
			[
				function (element) {
					return element.dates.startDate?.date;
				},
			],
			["desc"]
		);

	// FILTER ELEMENTS
	if (equalDefaultElementFilters(filters, periodLimits)) {
		// not necessary to filter
		return elements;
	}
	// filter on name
	if (!!name) {
		elements = elements.filter((element) =>
			element.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
		);
	}
	// filter on tags and period
	if (!(tags.length === 0 && period.min === periodLimits.min && period.max === periodLimits.max)) {
		if (tags.length > 0) {
			elements = elements.filter((element) => {
				// if an element contains at least one of the tags from the filter, then ok
				for (let i = 0; i < tags.length; i++) if (element.tags.includes(tags[i])) return true;
				return false;
			});
		}
		if (period.min !== periodLimits.min || period.max !== periodLimits.max)
			elements = elements.filter((element) => {
				if (!element.dates.startDate) return false;
				// if an element has a startDate and if this startDate is between the limits, then ok
				if (!element.dates.period)
					// no period means we check only the start date
					return (
						element.dates.startDate.date.getFullYear() >= period.min &&
						element.dates.startDate.date.getFullYear() <= period.max
					);
				else {
					// check if periods overlap : start1 <= end2 && start2 <= end1;
					if (!!element.dates.endDate) {
						return (
							element.dates.startDate.date.getFullYear() <= period.max &&
							period.min <= element.dates.endDate.date.getFullYear()
						);
					} else {
						return (
							element.dates.startDate.date.getFullYear() <= period.max &&
							period.min <= new Date().getFullYear()
						);
					}
				}
			});
	}
	// filter on year
	if (year !== defaultElementFilters.year)
		elements = elements.filter((element) => {
			if (!element.dates.startDate) return false;
			// if an element has no period but a startDate in the filtering year => ok
			if (!element.dates.period) return element.dates.startDate.date.getFullYear() === year;
			// if an element has period and the period includes the filtering year => ok
			else {
				if (!element.dates.endDate) {
					// check if start date is anterior or equal to filtering year
					return element.dates.startDate.date.getFullYear() <= year;
				} else {
					// check if start date is anterior or equal to filtering year and end date posterior or equal to filtering year
					return (
						element.dates.startDate.date.getFullYear() <= year &&
						element.dates.endDate.date.getFullYear() >= year
					);
				}
			}
		});
	// RETURN
	return elements;
}

// ----------- NARRATIVE MANAGEMENT -----------------

export function equalDefaultNarrativeFilters(filters: INarrativeFilters): boolean {
	// name
	if (filters.name !== defaultNarrativeFilters.name) return false;
	// otherwise true
	return true;
}

export function applyNarrativeFilter(
	narratives: INarrativeShort[],
	filters: INarrativeFilters,
	sortBy: string
): INarrativeShort[] {
	const { name } = filters;

	// SORT BY
	if (sortBy === "lastUpdated") narratives = orderBy(narratives, ["updatedAt"], ["desc"]);
	if (sortBy === "nameAsc") narratives = orderBy(narratives, ["name"], ["asc"]);
	if (sortBy === "nameDesc") narratives = orderBy(narratives, ["name"], ["desc"]);

	// FILTER ELEMENTS
	if (equalDefaultNarrativeFilters(filters))
		// not necessary to filter
		return narratives;

	if (!!name) {
		narratives = narratives.filter((narrative) =>
			narrative.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
		);
	}

	// RETURN
	return narratives;
}
