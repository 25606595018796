// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
	return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_ADMIN = "/admin";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
	root: ROOTS_AUTH,
	login: path(ROOTS_AUTH, "/login"),
	register: path(ROOTS_AUTH, "/register"),
	loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
	verify: path(ROOTS_AUTH, "/verify"),
	resetPassword: path(ROOTS_AUTH, "/reset-password"),
	newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_ADMIN = {
	root: ROOTS_ADMIN,
	login: path(ROOTS_ADMIN, "/login"),
	dashboard: path(ROOTS_ADMIN, "/dashboard/main"),
	users: path(ROOTS_ADMIN, "/dashboard/users"),
	events: path(ROOTS_ADMIN, "/dashboard/events"),
};

export const PATH_PAGE = {
	comingSoon: "/coming-soon",
	maintenance: "/maintenance",
	// pricing: '/pricing',
	// payment: '/payment',
	termsAndConditions: '/terms-and-conditions',
	privacyPolicy: '/privacy-policy',
	target: "/target",
	about: "/about-us",
	contact: "/contact-us",
	page403: "/403",
	page404: "/404",
	page500: "/500",
};

export const PATH_DASHBOARD = {
	root: path(ROOTS_DASHBOARD, "/workspace/list"),
	// user
	user: {
		root: path(ROOTS_DASHBOARD, "/user"),
		contacts: path(ROOTS_DASHBOARD, "/user/contacts"),
		account: path(ROOTS_DASHBOARD, "/user/account"),
	},
	// workspaces
	workspace: {
		list: path(ROOTS_DASHBOARD, "/workspace/list"),
		new: path(ROOTS_DASHBOARD, "/workspace/new"),
		root: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}`),
		settings: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/settings`),
		members: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/settings/members`),
		information: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/settings/information`),
		view: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/context/elements`),
	},
	exploration:{
		root: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/exploration`),
		elements: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/exploration/elements`),
		relations: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/exploration/relations`),
		geomap: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/exploration/geomap`),
		timelines: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/exploration/timelines`),
	},
	element: {		
		edit: (wsId: number, elId: number) =>
			path(ROOTS_DASHBOARD, `/workspace/${wsId}/element/${elId}/edit`),
		view: (wsId: number, elId: number) =>
			path(ROOTS_DASHBOARD, `/workspace/${wsId}/element/${elId}/view`),
	},
	history: {
		root: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/history`),
		narratives: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/history/narratives`),
	},
	narrative:{
		new: (wsId: number) => path(ROOTS_DASHBOARD, `/workspace/${wsId}/narrative/new`),
		edit: (wsId: number, naId: number) =>
			path(ROOTS_DASHBOARD, `/workspace/${wsId}/narrative/${naId}/edit`),
		view: (wsId: number, naId: number) =>
			path(ROOTS_DASHBOARD, `/workspace/${wsId}/narrative/${naId}/view`),
	},
	// other necessary
	permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
	blank: path(ROOTS_DASHBOARD, "/blank"),
};

export const PATH_SOCIALS = {
	discord: "https://discord.gg/BvPwWM5huY",
	linkedin: "https://www.linkedin.com/company/wiser-ly",
	twitter: "https://twitter.com/wiser_ly",
};
