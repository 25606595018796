// SOURCE => https://icon-sets.iconify.design/

import Iconify from "src/components/iconify/Iconify";

// -------------------------------------------------

// Public Navigation menu ->
export const IconHome = (props: any) => {
	return <Iconify icon="eva:home-fill" {...props} />;
};
export const IconTarget = (props: any) => {
	return <Iconify icon="mingcute:target-fill" {...props} />;
};
export const IconWhoWeAre = (props: any) => {
	return <Iconify icon="eva:people-fill" {...props} />;
};
export const IconContactUs = (props: any) => {
	return <Iconify icon="eva:message-circle-fill" {...props} />;
};
export const IconPage = (props: any) => {
	return <Iconify icon="eva:file-fill" {...props} />;
};
export const IconPrice = (props: any) => {
	return <Iconify icon="ri:price-tag-2-fill" {...props} />;
};

// Public Pages ->
export const IconOverview1 = (props: any) => {
	return <Iconify icon="gis:story-map" {...props} />;
};
export const IconOverview2 = (props: any) => {
	return <Iconify icon="material-symbols:explore-outline" {...props} />;
};
export const IconOverview3 = (props: any) => {
	return <Iconify icon="ph:student" {...props} />;
};
export const IconOverview4 = (props: any) => {
	return <Iconify icon="carbon:ibm-watson-discovery" {...props} />;
};


// Public Social links ->
export const IconDiscord = (props: any) => {
	return <Iconify icon="skill-icons:discord" {...props} />;
};
export const IconLinkedin = (props: any) => {
	return <Iconify icon="skill-icons:linkedin" {...props} />;
};
export const IconTwitter = (props: any) => {
	return <Iconify icon="skill-icons:twitter" {...props} />;
};

// -------------------------------------------------

// Dashboard Navigation menus ->
// User
export const IconProfile = (props: any) => {
	return <Iconify icon="mdi:user" {...props} />;
};
export const IconContacts = (props: any) => {
	return <Iconify icon="mdi:contact" {...props} />;
};
// Exploration
export const IconElement = (props: any) => {
	return <Iconify icon="mingcute:layout-7-fill" {...props} />;
};
export const IconList = (props: any) => {
	return <Iconify icon="material-symbols:list-alt-rounded" {...props} />;
};
export const IconCards = (props: any) => {
	return <Iconify icon="material-symbols:grid-view-rounded" {...props} />;
};
export const IconRelations = (props: any) => {
	return <Iconify icon="dashicons:networking" {...props} />;
};
export const IconGeoMap = (props: any) => {
	return <Iconify icon="wpf:geo-fence" {...props} />;
};
export const IconTimelines = (props: any) => {
	return <Iconify icon="icon-park-solid:timeline" {...props} />;
};
// Tasks
export const IconKanban = (props: any) => {
	return <Iconify icon="ph:kanban-fill" {...props} />;
};
// History
export const IconNarratives = (props: any) => {
	return <Iconify icon="material-symbols:menu-book-rounded" {...props} />;
};
// Workspaces
export const IconWSMembers = (props: any) => {
	return <Iconify icon="ic:sharp-group" {...props} />;
};
export const IconWSInfo = (props: any) => {
	return <Iconify icon="material-symbols:info" {...props} />;
};

// -------------------------------------------------

// Actions
export const IconNew = (props: any) => {
	return <Iconify icon="eva:plus-fill" {...props} />;
};
export const IconEdit = (props: any) => {
	return <Iconify icon="fa-solid:edit" {...props} />;
};
export const IconFindRelations = (props: any) => {
	return <Iconify icon="ph:share-network-bold" {...props} />;
};
export const IconView = (props: any) => {
	return <Iconify icon="carbon:view-filled" {...props} />;
};
export const IconOptions = (props: any) => {
	return <Iconify icon="material-symbols:more-vert" {...props} />;
};
export const IconAccept = (props: any) => {
	return <Iconify icon="material-symbols:check-circle-outline" {...props} />;
};
export const IconDelete = (props: any) => {
	return <Iconify icon="material-symbols:delete-rounded" {...props} />;
};
export const IconSearch = (props: any) => {
	return <Iconify icon="eva:search-fill" {...props} />;
};
export const IconClear = (props: any) => {
	return <Iconify icon="mdi:clear-circle-outline" {...props} />;
};
export const IconCopyToClipboard = (props: any) => {
	return <Iconify icon="lucide:clipboard-copy" {...props} />;
};
export const IconClose = (props: any) => {
	return <Iconify icon="eva:close-fill" {...props} />;
};
export const IconFilter = (props: any) => {
	return <Iconify icon="ic:round-filter-list" {...props} />;
};
export const IconClearFilter = (props: any) => {
	return <Iconify icon="icon-park-outline:clear" {...props} />;
};
export const IconExpand = (props: any) => {
	return <Iconify icon="gg:arrows-expand-left" {...props} />;
};
export const IconMoveUp = (props: any) => {
	return <Iconify icon="mingcute:up-fill" {...props} />;
};
export const IconMoveDown = (props: any) => {
	return <Iconify icon="mingcute:down-fill" {...props} />;
};

// -------------------------------------------------

// Workspace Rights
export const IconOwner = (props: any) => {
	return <Iconify icon="tabler:user-shield" {...props} />;
};
export const IconAdmin = (props: any) => {
	return <Iconify icon="tabler:user-cog" {...props} />;
};
export const IconWrite = (props: any) => {
	return <Iconify icon="tabler:user-edit" {...props} />;
};
export const IconRead = (props: any) => {
	return <Iconify icon="tabler:user-search" {...props} />;
};
export const IconNone = (props: any) => {
	return <Iconify icon="tabler:user-x" {...props} />;
};

// -------------------------------------------------

// Account
export const IconAccount = (props: any) => {
	return <Iconify icon="mdi:user" {...props} />;
};
export const IconPassword = (props: any) => {
	return <Iconify icon="ic:round-vpn-key" {...props} />;
};

// -------------------------------------------------

// Element details
export const IconElementInfo = (props: any) => {
	return <Iconify icon="material-symbols:info" {...props} />;
};
export const IconElementGeo = (props: any) => {
	return <Iconify icon="wpf:geo-fence" {...props} />;
};
export const IconElementMedias = (props: any) => {
	return <Iconify icon="fa6-solid:images" {...props} />;
};
export const IconElementRelations = (props: any) => {
	return <Iconify icon="dashicons:networking" {...props} />;
};
export const IconElementSources = (props: any) => {
	return <Iconify icon="tabler:external-link" {...props} />;
};

// -------------------------------------------------

// Relation Graph
export const IconElementNodeInfo = (props: any) => {
	return <Iconify icon="material-symbols:info" {...props} />;
};
export const IconRestorePositions = (props: any) => {
	return <Iconify icon="material-symbols:upload" {...props} />;
};
export const IconSavePositions = (props: any) => {
	return <Iconify icon="material-symbols:download-rounded" {...props} />;
};
export const IconFullScreen = (props: any) => {
	return <Iconify icon="map:fullscreen" {...props} />;
};
export const IconElementSizes = (props: any) => {
	return <Iconify icon="vaadin:resize-v" {...props} />;
};


// -------------------------------------------------

// Geo Map
export const IconGeoloc = (props: any) => {
	return <Iconify icon="bi:geo-fill" {...props} />;
};

// -------------------------------------------------

// Hint & Tips
export const IconHint = (props: any) => {
	return <Iconify icon="ic:outline-tips-and-updates" {...props} />;
};

// -------------------------------------------------

// Admin
export const IconMain = (props: any) => {
	return <Iconify icon="ci:main-component" {...props} />;
};
export const IconUsers = (props: any) => {
	return <Iconify icon="fa6-solid:users" {...props} />;
};
export const IconEvents = (props: any) => {
	return <Iconify icon="mdi:events" {...props} />;
};

// -------------------------------------------------
