// routes
import { PATH_DASHBOARD, PATH_ADMIN } from './routes/paths';

// API
// ----------------------------------------------------------------------

const api = process.env.REACT_APP_HOST_API_URL ?? '';
const app = process.env.REACT_APP_HOST_APP_URL ?? '';

export const HOST_API_URL = (!api.endsWith('/')) ? api.concat('/') : api ;
export const HOST_APP_URL = (!app.endsWith('/')) ? app.concat('/') : app ;


export const MAP_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root; // as '/dashboard/workspace/cards'
export const PATH_AFTER_ADMIN_LOGIN = PATH_ADMIN.dashboard; // as '/dashboard/workspace/cards'

// LAYOUT
// ----------------------------------------------------------------------

export const HERO = {
  H_HERO_IMAGE: 500,
};

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_BASE_BIG: 360,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

// ----------------------------------------------------------------------
// MAX FIELD LENGTHS
export const MAX_LENGTH = {
  NAME : 50,
  PASSWORD: 64,
  EMAIL: 100,
  ABOUT: 300,
  IMAGE_BYTES: 10485760,
  ELEMENT_NAME: 100,
  ELEMENT_DATE: 10,
  ELEMENT_TAG: 40,
}

// ----------------------------------------------------------------------
// NARRATIVE CHAPTER IMAGE SIZE 
export const NARRATIVE_CHAPTER_IMAGE_SIZE = {
  WIDTH: 80,
  HEIGHT: 80,
}

// ----------------------------------------------------------------------
// DEFAULT ILLUSTRATIONS 
export const DEFAULT_ELEMENT_IMAGE = "/assets/illustrations/exploration/default_element.png";
export const DEFAULT_WORKSPACE_IMAGE = "/assets/illustrations/workspaces/default_workspace.png";
export const DEFAULT_NARRATIVE_IMAGE = "/assets/illustrations/history/default_narrative.png";
export const DEFAULT_NARRATIVE_CHAPTER_IMAGE = "/assets/illustrations/history/default_narrative_chapter.png";


// ----------------------------------------------------------------------
// SNACKBAR DURATION

export const SNACKBAR_DURATION_SUCCESS = 5000;
export const SNACKBAR_DURATION_FAILURE = 10000;